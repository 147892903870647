import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ProductCard from '../components/product-card'
import SEO from '../components/seo'
import Breadcrumbs from '../components/navigation/breadcrumbs'
import { getListPageURL, getSlug } from '../utils/url'

const ListTemplate = ({ pageContext, data }) => {
	const { title, slug, products } = pageContext

	return (
		<Layout>
			<SEO title={title} />
			<Breadcrumbs url={getListPageURL(getSlug(slug))} />
			<h1 className="uk-heading-line">
				<span>{title}</span>
			</h1>
			<div id="products">
				{products &&
					products.map((product) => (
						<ProductCard key={getSlug(product)} product={product} />
					))}
			</div>
		</Layout>
	)
}

ListTemplate.propTypes = {
	pageContext: PropTypes.object,
}

export default ListTemplate
